import "./Portfolio.scss";

import { Link } from "gatsby";
import React from "react";
import Polygon from "../../assets/imgs/portfolio/Polygon.png";
import Button from "../../components/widgets/Button/Button";
import { Header } from "../Header/Header";
import Img from "gatsby-image";

interface WebsiteLink {
  text: string;
  link: string;
}

export interface IProduct {
  name: string;
  description: string;
  images: string[];
  customImgStyle?: object;
  use?: string;
  descriptionCustomStyle?: object;
  websiteLink?: WebsiteLink;
  link?: null | string;
  features?: string[];
  responsibilities?: string[];
  technology?: string[];
  customImgClass?: string;
  hidePolygon?: boolean;
}

interface IProps {
  products: Array<IProduct>;
  noTitle?: boolean;
  noSubTitle?: boolean;
  customSubTitle?: any;
}

export const Portfolio = ({
  products,
  noTitle,
  noSubTitle,
  customSubTitle,
}: IProps) => {
  return (
    <div className="portfolio-container">
      <Header
        title={"Portfolio"}
        subTitle={customSubTitle ? customSubTitle : "We develop great products"}
      />
      <div className="portfolio-items">
        {products.map((product, i) => {
          console.log(product.images[0]);
          return (
            <div className="product" key={`product-${product.name}`}>
              <ProductDescription product={product} index={i} />
              <div
                className={`image ${
                  product.customImgClass ? product.customImgClass : ""
                }`}
              >
                {product.images[0].childImageSharp ? (
                  <Img
                    fixed={product.images[0].childImageSharp.fixed}
                    className="product-image"
                    style={{ ...product.customImgStyle, display: "inherit" }}
                  />
                ) : (
                  <img
                    src={product.images[0]}
                    className="product-image"
                    style={{ ...product.customImgStyle, display: "inherit" }}
                  />
                )}
                {!product.hidePolygon && (
                  <img src={Polygon} className="polygon" />
                )}
              </div>
              {product.link ? (
                <div className="case-study">
                  <Link to={`/portfolio/case-study/${product.link}`}>
                    <Button text="CASE STUDY" style={{ float: "right" }} />
                  </Link>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ProductDescriptionProps {
  product: IProduct;
  index: number;
}

const ProductDescription = ({ product, index }: ProductDescriptionProps) => {
  let productNumber = index + 1 > 10 ? index + 1 : `0${index + 1}`;
  return (
    <div className="content">
      <div className="name">
        <span>{productNumber}</span>
        {product.name}
      </div>
      <div className="description">
        {product.websiteLink && (
          <a href={product.websiteLink.link} target="_blank">
            {product.websiteLink.text}
          </a>
        )}
        {product.description}
      </div>
      {product.use && (
        <div className="use">
          <h3>Use:</h3>
          <p>{product.use}</p>
        </div>
      )}
      {product.features && (
        <div className="features">
          <h3>Key features:</h3>
          <ul>
            {product.features.map((feature) => (
              <li key={feature}>{feature}</li>
            ))}
          </ul>
        </div>
      )}
      {product.responsibilities && (
        <div className="responsibilities">
          <h3>Services provided:</h3>
          <p>{product.responsibilities.join(" + ")}</p>
        </div>
      )}
      {product.technology && (
        <div className="technology">
          <h3>Services provided:</h3>
          <p>{product.technology.join(" + ")}</p>
        </div>
      )}
    </div>
  );
};
