import { ContinueReading } from "../../components/ContinueReading";
import { IProduct, Portfolio } from "../../components/Portfolio";
import React from "react";
import { TestimonialSlider } from "../../components/TestimonialSlider";
import Seo from "../../components/Seo/seo";
import { graphql } from "gatsby";

function PortfolioPage({ data }) {
  console.log(data);

  let isGooglePlay = true;
  if (typeof navigator != "undefined") {
    if (/Android/i.test(navigator.userAgent)) {
      isGooglePlay = true;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      isGooglePlay = false;
    } else if (/Mac/i.test(navigator.userAgent)) {
      isGooglePlay = false;
    } else if (/Windows/i.test(navigator.userAgent)) {
      isGooglePlay = true;
    }
  }
  const Products: IProduct[] = [
    {
      name: "Event portal",
      description:
        " web portal with a video history of the talks given at the event.",
      use:
        "This web portal allows users to learn about the event, register and define how you want to participate. Finally, people can re-experience the event via a series of videos.",
      features: [
        "Explore event schedules and topic threads",
        "Registration and profile management",
        "Watch videos of the talks",
      ],
      responsibilities: ["Development"],
      technology: ["React.js", "Gatsby"],
      images: [data.tedxwarsaw],
      websiteLink: {
        link: "https://tedxwarsaw.org",
        text: "TEDxWarsaw",
      },
      customImgStyle: { marginTop: "50px", transform: "translateX(30px)" },
      customImgClass: "horizontal-image",
    },

    {
      name: "Health platform",
      description:
        " for patients and health care professionals. Digitalised patient care and support for remote monitoring.",
      use:
        "Support for therapy and coordination of therapeutic processes in health care facilities. Help patients follow treatment advice and improve the effectiveness of coordinated care.",
      features: [
        "Access to the platform on any device",
        "Mobile application for both the doctor and patient",
        "Appointment scheduling and management for both the doctor and the patient",
        "Patient’s medical knowledge base",
        "Email and sms reminders for upcoming appointments.",
        "Possibility of sending recommendations to the patient directly to his device",
        "Automated pre-visit forms",
        "Reminders for measurements, taking medicines, and activities",
      ],
      responsibilities: ["UX/UI design + Development"],
      technology: ["Cross-Platform Flutter - Android, iOS & web"],
      images: [data.healthPlatform],
      websiteLink: {
        link: isGooglePlay
          ? "https://play.google.com/store/apps/details?id=health.allbright.kos&hl=pl"
          : "/https://apps.apple.com/us/app/ahp-kos-zawał/id1618585314",
        text: "AllBright Health Platform",
      },
      customImgStyle: { marginTop: "50px", transform: "translateX(30px)" },
      customImgClass: "horizontal-image",
    },

    {
      name: "Medical Bookings App",
      description:
        " is a web-based medical appointments scheduling and management system.",
      use:
        "This app provides booking and appointment management when high-security is essential. It integrates with hospital internal EMR systems, telemedicine consultations, and the health monitoring apps used by patients.",
      features: [
        "Booking and appointment management",
        "Integrates with hospital EMR systems",
        "Telemedicine consultations",
        "Meets the highest security standards",
      ],
      responsibilities: ["UX/UI design", "Development"],
      technology: ["React.js", "Django"],
      images: [data.medrefer],
      // link: "medrefer",
      websiteLink: {
        link: "https://www.medrefer.de/en",
        text: "Medrefer.de",
      },
      customImgStyle: { marginTop: "50px", transform: "translateX(30px)" },
      customImgClass: "horizontal-image",
    },
    {
      name: "Health App",
      description:
        " is an app that helps people with heart disease keep up with their physical and medical routines.",
      use:
        "The app makes it easier for heart patients to monitor their health, minimize the disease's risk, and receive support during the recovery process.",
      features: [
        "Blood pressure, heart rate, and weight tracking",
        "Medical and physical activity notifications",
        "Feedback on personal routines",
        "Google Fit integration",
        "Medical algorithms to monitor patient health",
      ],
      responsibilities: ["UX/UI design", "Development"],
      technology: ["Cross-Platform Flutter - Android, iOS & web"],
      images: [data.kardiolog],
      // link: "kardiolog",
      websiteLink: {
        link: "https://kardiolog.app",
        text: "KardioLog",
      },
      customImgStyle: { marginTop: "-20px" },
    },
    {
      name: "Video-chat App",
      description:
        " is a video-chat application designed to help hearing-impaired patients communicate with ease.",
      use:
        "The video-chat app’s real-time transcription allows people with hearing loss to communicate easily via mobile devices or a web browser. The app can also increase accessibility during telemedicine appointments, customer care sessions, and company conferences.",
      features: [
        "Video-chat communication",
        "Real-time speech transcription",
        "Peer-to-peer data transmission",
        "Designed for high accessibility to users with impaired eyesight",
      ],
      responsibilities: ["UX/UI design", "Development"],
      technology: ["React progressive web app"],
      images: [data.captio],
      // link: "captio",
      websiteLink: {
        link: "https://captio.cc/",
        text: "Captio",
      },
      customImgStyle: { maxHeight: "420px", marginTop: "60px" },
    },
    {
      name: "Mobile Wallet App",
      description:
        "Mobile wallet app giving access to customers prepaid accounts.",
      use:
        "The app enables contactless NFC payments, peer-to-peer transactions and includes a customer loyalty programme. Supports both physical and virtual cards.",
      features: [
        "Highly secure and PCI compliant",
        "Map of local deals",
        "Near field payments",
        "Peer-to-peer transactions  ",
      ],
      responsibilities: ["UX/UI design", "Development"],
      technology: ["Android (native)", "iOS (native)"],
      images: [data.mobileWallet],
      customImgStyle: { marginTop: "0" },
    },
    {
      name: "Business App",
      description:
        " is a cross-platform (Android/iOS) application that helps individuals in the FinTech industry connect and build community.",
      use:
        "Business people can use this software to investigate and grow their networks inside a certain organization or community. Users can search for and browse members of their group by field, need, focus, or ability, and engage with them via a custom-built chat tool.",
      features: [
        "Real-time, one-to-one and group chats",
        "Search and browse member profiles",
        "New message notifications",
        "Extended member business profiles",
        "Dark mode",
      ],
      responsibilities: ["UX/UI design", "Development"],
      technology: ["Cross-Platform Flutter - Android & iOS"],
      images: [data.fintechConnector],
      link: "fintech-connector",
      websiteLink: {
        link: "https://www.fintechconnector.com",
        text: "FinTech Connector",
      },
      customImgStyle: { maxHeight: "486px", marginTop: "30px" },
    },
    {
      name: "Loyalty App",
      use:
        "Mobile app that allows users to interact with a loyalty program. They can collect and redeem points, and trade them for gifts, vouchers or cashback.",
      description:
        "Loyalty application designed to increase customer engagement.",
      features: [
        "Campaigns list",
        "Map view",
        "Loyalty points card",
        "Rewards",
      ],
      responsibilities: ["UX/UI design", "Development"],
      technology: ["Android (native)", "iOS (native)"],
      images: [data.loyaltyApp],
      customImgStyle: { maxHeight: "486px", marginTop: "30px" },
    },
  ];

  return (
    <>
      <Seo title={"Portfolio"} />
      <div className="sections">
        <section>
          <Portfolio products={Products} />
        </section>
        <section>
          <TestimonialSlider page="portfolio" hideMobile />
        </section>
        <section>
          <ContinueReading nextSection={"Blog"} link="blog" />
        </section>
      </div>
    </>
  );
}

export default PortfolioPage;

export const query = graphql`
  query {
    tedxwarsaw: file(
      relativePath: { eq: "imgs/portfolio/TEDxWarsawBlack.png" }
    ) {
      childImageSharp {
        fixed(width: 700, height: 445, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    healthPlatform: file(
      relativePath: { eq: "imgs/portfolio/HealthPlatform.png" }
    ) {
      childImageSharp {
        fixed(width: 700, height: 475, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    medrefer: file(relativePath: { eq: "imgs/portfolio/MedreferBlack.png" }) {
      childImageSharp {
        fixed(width: 700, height: 445, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kardiolog: file(
      relativePath: { eq: "imgs/portfolio/KardiologHeroBlack.png" }
    ) {
      childImageSharp {
        fixed(width: 433, height: 610, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    captio: file(relativePath: { eq: "imgs/portfolio/CaptioBlack.png" }) {
      childImageSharp {
        fixed(width: 550, height: 420, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mobileWallet: file(
      relativePath: { eq: "imgs/portfolio/MobileWalletApp.png" }
    ) {
      childImageSharp {
        fixed(width: 620, height: 570, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fintechConnector: file(
      relativePath: { eq: "preview-fintechconnector.png" }
    ) {
      childImageSharp {
        fixed(width: 279, height: 486, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    loyaltyApp: file(relativePath: { eq: "imgs/portfolio/LoyaltyApp.png" }) {
      childImageSharp {
        fixed(width: 433, height: 486, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
